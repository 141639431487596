.services {
  padding: 1rem 0;
}

.services-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #005f73;
  text-align: center;
  margin-bottom: 2rem;
  transition: color 0.3s ease-in-out;
}

.services-title:hover {
  color: #00323c;
}

.services-description {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #5f7074; 
  margin-bottom: 2rem;
  text-align: center;
  transition: color 0.3s ease-in-out;
}

.services-description:hover {
  color: #4c6870; 
}

.services-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-card {
  border: none;
  text-align: center;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); 
    backdrop-filter: blur(1px);
    z-index: 1;
  }

  .card-body {
    position: relative;
    z-index: 2;

    .card-title {
      font-size: 1.8rem;
      font-weight: bold;
      color: #4c6870; 
      text-shadow: none;
      margin-top: 1rem;
      transition: color 0.3s ease-in-out;
    }

    .card-text {
      font-size: 1.1rem;
      color: #5f7074; 
    }
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    background-position: left !important;
  }
}

.service-card .card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4c6870; 
  margin-top: 1rem;
  transition: color 0.3s ease-in-out;
}

.service-card .card-title:hover {
  color: #3b5560; 
}

.service-card .card-text {
  font-size: 1rem;
  color: #5f7074; 
}

@media (max-width: 768px) {
  .service-card {
    flex: 0 0 100%;
    margin-bottom: 1rem;
  }

  .service-card .card-title {
    font-size: 1.25rem;
  }

  .service-card .card-text {
    font-size: 0.9rem;
  }
}

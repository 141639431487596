/* src/components/Home.scss */

.hero-section {
  background: transparent;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.octopod-logo-rectangle {
  width: 100%;
  max-width: 600px;
  height: auto;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 4 / 3;
  transition: transform 0.6s ease;

  &:hover {
    transform: scale(1.05);
  }
}

.hero-text {
  color: #121212;
  text-align: center;
  font-size: 1.5rem;
  margin-top: 1rem;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.btn-dark {
  background-color: #13c2dc; 
  border-color: #13c2dc;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: pulse 2.5s ease-in-out infinite; 

  &:hover {
    transform: scale(1.05); 
    box-shadow: 0 6px 12px rgba(0, 195, 220, 0.3);
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05); 
    opacity: 0.9; 
  }
}

.button-group {
  display: flex;
  gap: 1rem;

  .btn-primary {
    background-color: #13c2dc;
    border: none;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: pulse 2.5s ease-in-out infinite;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 6px 12px rgba(0, 195, 220, 0.3);
    }
  }

  .btn-secondary {
    background-color: #005f73;
    border: none;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.9;
  }
}

@media (max-width: 576px) { 
  .button-group {
    flex-direction: column;
    gap: 0.5rem; 
  }

  .button-group .btn {
    width: 100%; 
  }
}

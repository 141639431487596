.about {
  padding: 1rem 0;
}

.about-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #005f73;
  text-align: center;
  margin-bottom: 2rem;
  transition: color 0.3s ease-in-out;
}

.about-title:hover {
  color: #00323c;
}

.about-description {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #343a40;
  margin-bottom: 1rem;
}

.about-team-title,
.about-projects-title,
.legal-info-title {
  font-size: 2rem;
  font-weight: bold;
  color: #005f73;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  transition: color 0.3s ease-in-out;
}

.about-team-title:hover,
.about-projects-title:hover,
.legal-info-title:hover {
  color: #00323c;
}

.about-team,
.about-projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.team-member,
.project-card {
  margin-bottom: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.team-member:hover,
.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.team-photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
  transition: box-shadow 0.3s ease-in-out;
}

.team-photo:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.card {
  border: none;
  text-align: center;
  padding: 1rem;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #005f73;
  margin-top: 1rem;
  transition: color 0.3s ease-in-out;
}

.card-title:hover {
  color: #00323c;
}

.card-text {
  font-size: 1rem;
  color: #343a40;
}

.legal-info {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.legal-info:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.legal-info p {
  font-size: 1rem;
  color: #343a40;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .team-member,
  .project-card {
    flex: 0 0 100%;
  }

  .team-photo {
    width: 150px;
    height: 150px;
  }

  .card-title {
    font-size: 1.25rem;
  }

  .card-text {
    font-size: 0.9rem;
  }
}

/* src/App.scss */

:root {
  --primary-color: #13c2dc;
  --secondary-color: #0f9fb5;
  --background-color: #e0f7fb;
  --text-color: #ffffff;
}

body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100%;
}

#root {
  min-height: 100vh;
}

.app-container {
  background: linear-gradient(to bottom, #e0f7fb, #a6e4ee);
  min-height: 100vh;
  position: relative;
}

.section {
  padding-top: 50px;
}

@media (max-width: 768px) {
  .container,
  .container-fluid,
  .section {
    padding: 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    padding: 0 1rem;
  }

  .card {
    margin-bottom: 1.5rem;
    padding: 1rem;
  }
}

p,
.about-description,
.card-text,
.services-description {
  text-align: justify;
  word-break: break-word;
  //overflow-wrap: break-word;
  hyphens: auto;
}

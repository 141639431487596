// src/components/Contact.scss

.contact {
  padding: 1rem 0;
}

.contact-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #005f73;
  text-align: center;
  margin-bottom: 2rem;
  transition: color 0.3s ease-in-out;
}

.contact-title:hover {
  color: #00323c;
}

.contact-description {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #343a40;
  margin-bottom: 1rem;
  text-align: center;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  text-align: left;
}

.contact-form:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.contact-form .form-group {
  margin-bottom: 1.5rem;
}

.contact-form .form-control {
  border-radius: 0.25rem;
}

.contact-form .btn {
  background-color: #005f73;
  border-color: #005f73;
  width: 100%;
  padding: 0.75rem;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-form .btn:hover {
  background-color: #004d60;
  transform: translateY(-5px);
}

.thank-you-message {
  max-width: 500px;
  margin: 2rem auto;
  padding: 1.5rem;
  text-align: center;
  background-color: #f0fdff;
  color: #005f73;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-info {
  margin-top: 2rem;
  text-align: center;
  word-break: break-word;
}

@media (max-width: 576px) { 
  .contact-info {
    font-size: 1rem; 
  }
}

.contact-form .form-group label {
  margin: 0.5rem; 
  display: inline-block;
  font-weight: bold;
}


.thank-you-message h4 {
  font-size: 2rem; 
  margin-bottom: 1rem;
}

.thank-you-message p {
  font-size: 1.2rem; 
  color: #343a40;
  text-align: center;
}


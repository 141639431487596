.custom-navbar {
  background-color: #ffffff !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-logo-img {
  height: 45px;
}

.nav-link {
  font-size: 1.1rem;
  color: black !important;
  padding: 0.5rem 1rem;
  margin-right: 10px;
  position: relative;
  text-decoration: none;
  transition: color 0.3s ease;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 0;
    height: 2px;
    background-color: #13c2dc;
    transition: width 0.3s ease;
  }

  &:hover {
    color: #0f9fb5 !important;

    &::after {
      width: 100%;
    }
  }
}

.language-switch {
  background-color: transparent;
  border: none;
  padding: 4px;
  margin-left: 5px;
  transition: transform 0.3s;
  border-radius: 50%;
  display: inline-flex; 
  align-items: center; 
  justify-content: center; 
  width: 40px; 
  height: 40px; 

  &.active {
    border: 2px solid #13c2dc;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.language-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
}

@media (max-width: 576px) {
  .language-switch {
    width: 36px; 
    height: 36px; 
  }

  .language-icon {
    width: 18px;
    height: 18px;
  }
}
